/* eslint-disable max-classes-per-file */
/* eslint-env browser */
/* eslint-disable no-await-in-loop */

import angular from "angular";
import {toJpeg} from "html-to-image";
import groupBy from "lodash/groupBy";
import {DateTime} from "luxon";
import moment from "moment";
import {PageSizes, PDFDict, PDFDocument, PDFName, PDFString} from "pdf-lib";
import semver from "semver";

import {formatDateAndTime} from "../../components/DateAndTime/DateAndTime.jsx";
import assignStudyPug from "../../dialogs/assignStudy/assignStudy.pug";
import editReportPug from "../../dialogs/editReport/editReport.pug";

/*
 * A sheet represents a single page of a report. A div.sheet is created for each sheet.
 * Properties:
 *   config: Report config object. Specifies which elements should be displayed, the order they
 *           should be displayed in, and miscellaneous styling.
 *           Currently set to DEFAULT_REPORT_CONFIG, which is set up by us.
 *           Could eventually be set up by a facility admin(through a ui).
 *
 *   MAX_HEIGHT: Set to the number of pixels equivalent to one 11" sheet of paper, less the height
 *               of the footer, which is on every page.
 *               Used when constructing the elements array to decide whether a report element will
 *               fit on the page or if a new page must be created.
 *
 *  height: The height of all of the elements on the page in pixels. Used for reference during
 *          element array construction.
 *
 *  elements: An array of the report elements that will be displayed on the sheet--in order.
 *
 * Example of a Sheets object:
 *
 *   {
 *     1: [
 *       config: {
 *         See reportConfig.constant.js
 *       },
 *       MAX_HEIGHT: 1017,
 *       height: 620,
 *       elements: [
 *         {type: "header", stripIndex: null, stripSegment: null},
 *         {type: "summary", stripIndex: null, stripSegment: null},
 *         {type: "stripHeader", stripIndex: 0, stripSegment: null},
 *         {type: "stripSegment", stripIndex: 0, stripSegment: 0},
 *         {type: "stripSegment", stripIndex: 0, stripSegment: 1},
 *         {type: "stripFooter", stripIndex: 0, stripSegment: null},
 *         {type: "physicianComments", stripIndex: null, stripSegment: null},
 *         {type: "footer", stripIndex: null, stripSegment: null},
 *       ]
 *     ],
 *     2: [ ... ]
 *   }
 */

class Sheet {
  constructor(config, sheetNumber) {
    this.config = config;
    this.id = `report-sheet-${sheetNumber}`;
    this.MAX_HEIGHT = 1008 - config.footer.height; // 1008 = (11 - 0.25 - 0.25) * 96
    this.reset();
  }

  reset() {
    this.elements = [];
    this.height = 0; // This will be maintained by the instance, since all heights are known
  }

  addHeader() {
    this.addElement({type: "header"});
  }

  /**
   * The DOM should be updated before calling this function.
   * @returns {Element}
   */
  get element() {
    return document.getElementById(this.id);
  }

  get remainingHeight() {
    return this.MAX_HEIGHT - this.height;
  }

  finalize() {
    // Add Footer
    this.addElement({type: "footer"});

    // Return Placeholder Elements to their original states
    for (let i = 0; i < this.elements.length; i++) {
      const element = this.elements[i];
      if (element.type === "placeholder") {
        element.type = element.originalType;
        delete element.placeholderHeight;
        delete element.originalType;
      }
    }

    // Remove any vertical spacers if there is another growing element
    const growElements = ["physicianComments", "eSignPhysicianComments"];
    const hasGrowElement = !!this.elements.find((e) => {
      return growElements.includes(e.type);
    });
    if (hasGrowElement) {
      this.elements = this.elements.filter(({type}) => type !== "verticalSpacer");
    }
  }

  addElement(element, elementHeight = null) {
    const configElementHeight = this.config[element.type].height;
    const isFixedHeight = configElementHeight !== null;
    if (isFixedHeight) {
      element.originalType = element.type;
      element.type = "placeholder";
      element.placeholderHeight = configElementHeight;
    }

    const height = elementHeight || configElementHeight || 0;

    this.elements.push(element);
    this.height += height;
  }
}

/* @ngInject */
export default class GeneratedReportController {
  constructor($rootScope, $scope, $stateParams, DEFAULT_REPORT_CONFIG, $injector) {
    this._$rootScope = $rootScope;
    this._$scope = $scope;
    this._$stateParams = $stateParams;
    this._$window = $injector.get("$window");
    this._$mdDialog = $injector.get("$mdDialog");
    this._$state = $injector.get("$state");
    this._Config = $injector.get("Config");
    this._backendConfig = $injector.get("backendConfig");
    this._Facility = $injector.get("Facility");
    this._EventService = $injector.get("EventService");
    this._DonutChartService = $injector.get("DonutChartService");
    this._ArrhythmiaDataService = $injector.get("ArrhythmiaDataService");
    this._Authentication = $injector.get("Authentication");
    this._veDataService = $injector.get("VentricularEctopyDataService");
    this._afStatsService = $injector.get("AtrialFibrillationStatsService");
    this._ExcludeArtifactService = $injector.get("ExcludeArtifactService");
    this._StripService = $injector.get("StripService");
    this._Markers = $injector.get("Markers");
    this._GeneratedReportService = $injector.get("GeneratedReportService");
    this._StudyService = $injector.get("StudyService");
    this._StudyAssignmentService = $injector.get("StudyAssignmentService");
    this._WorkflowsService = $injector.get("WorkflowsService");
    this._InboxItemService = $injector.get("InboxItemService");
    this._UserService = $injector.get("UserService");

    this.features = this._Config.features;
    this.DEFAULT_REPORT_CONFIG = DEFAULT_REPORT_CONFIG;
    this.reportConfig = angular.copy(DEFAULT_REPORT_CONFIG);
    this.isLoading = false;
    this.isRendering = false;
    this.maxPhysicianCommentsHeight = this.reportConfig.eSignPhysicianComments.height;
    this.displayTechnicianSignature = "";

    this.$onInit = this._init;
  }

  /**
   * Generated Report is used on the Generated Reports Page
   *
   * this.reportId and this.reportType are passed from directive attributes and unavailable until $onInit
   */
  async _init() {
    if (this._$stateParams.reportId && this._$stateParams.type) {
      this.reportType = this._getUserFriendlyReportType(this._$stateParams.type);
      this.reportId = this._$stateParams.reportId;
    } else {
      this.loadingError = "Error while loading report: No type/id given";
      return;
    }

    this.isLoading = true;
    this.controlBox = {
      editing: false,
      submitting: false,
      publishing: false,
      signing: false,
      rejecting: false,
      isLoading: () => {
        return this.editing || this.submitting || this.publishing || this.signing || this.rejecting;
      },
    };

    try {
      this.loadingMessage = "Loading report"; // Fetch data from API
      const allData = await this._getData();
      this.logo = allData.logoInfo.logo;
      this.logoFilename = allData.logoInfo.logoFilename;
      this._generatedReport = allData.generatedReport;
      this._study = allData.study;
      this._originalItem = allData.originalItem;

      this._setReportConfigValues();

      this.logoExtension = this._Facility.getLogoFileExtension(this.logoFilename);
      this._versionCreated = this._generatedReport.version;
      this.strips = this._generatedReport.strips.map((strip) => {
        strip.validStartTime = moment(strip.startTime).year() >= 2010;

        // Initialize measurements
        this._getMeasurementsTable(strip);

        return strip;
      });
      this.meetsMdnCriteria = this._generatedReport.meetsMdnCriteria;
      this.markAsFinalized =
        !!this._study.finalizedAt || ["Uploaded", "Summary"].includes(this._generatedReport.reportType);
      this.generatedByUser = this._generatedReport.generatedByUser;
      this.lastModifiedByUser = this._generatedReport.lastModifiedByUser;
      this.studyType = this._study.studyType;
      this.reportComments = this._generatedReport.comment;

      this.displayedTechnicianName = this._generatedReport.technicianSignedBy;
      this.displayedTechnicianSignature =
        this._generatedReport.technicianSignedBy &&
        !["published", "generated"].includes(this._generatedReport.state)
          ? `Electronically signed by ${this._generatedReport.technicianSignedBy}`
          : null;
      this.displayedTechnicianTimestamp = this._generatedReport.technicianSignedAt
        ? formatDateAndTime({datetime: this._generatedReport.technicianSignedAt, zone: this._study.timeZone})
        : null;

      this.chartToggles = this._generatedReport.chartToggles;
      this.beatMarkers = this._generatedReport.beatMarkers || [];
      this.reportTitle = `${this._study.id.toString()}-${this._generatedReport.reportNumber}`;
      document.title = `Generated Report ${this.reportTitle} - BitRhythm Inbox`;
      this.eSignEnabled = this._WorkflowsService.workflowSettings[this._study.facilityId]?.eSignEnabled;

      // Use the recorded duration from the study, if present
      let durationToUse = this._study?.recordedDuration;

      // Otherwise, use a calculated value from the start and end time
      if (!durationToUse) {
        durationToUse = DateTime.fromISO(this._study?.studyEndDate)
          .diff(DateTime.fromISO(this._study?.studyStartDate), "hours")
          .as("hours");
      }

      // As a last option, use the configured duration for the study
      if (!durationToUse && this._study?.configuredDuration) {
        durationToUse = this._study.configuredDuration;
      }
      this.recordingDuration = Math.ceil(durationToUse / 24);

      this._formatReportComments();
      this._formatReportSummary();

      if (["Daily Trend", "Summary"].includes(this.reportType)) {
        this._formatAnalysisSummary();
        this._formatStripIndex();
        this._formatChartsAndGraphs();
      }

      this.sheets = {};
      await this._createSheets();
      this.isLoading = false;
      this.loadingMessage = "";
      this._$scope.$apply();
    } catch (error) {
      this.isLoading = false;
      this.loadingMessage = "";

      const defaultError = "Error while loading report";
      if (error.status || error.statusText) {
        this.loadingError = `${error.status || ""} ${error.statusText || defaultError}`;
      } else if (error.message) {
        this.loadingError = error.message;
      } else {
        this.loadingError = defaultError;
      }
    }
  }

  closeDialog(fileUrl) {
    if (fileUrl) {
      this._$window.open(fileUrl);
    }
    this._$mdDialog.hide();
  }

  /**
   * Checks if the user role is within the allowed roles
   * @param {string[]} allowedRoles
   * @returns {boolean}
   */
  isAuthorized(allowedRoles) {
    return this._Authentication.isInAnyRole(allowedRoles);
  }

  displayEditButton() {
    return this.features.correctReport && this._generatedReport?.state === "generated";
  }

  displayMdnToggle() {
    return this.displaySubmitButton() || this.displayPublishButton();
  }

  displayMarkAsFinalizedToggle() {
    return this.features.finalizeStudy && (this.displaySubmitButton() || this.displayPublishButton());
  }

  displaySubmitButton() {
    return (
      !this.isAuthorized(["clinicalStaff", "physician"]) &&
      this.eSignEnabled &&
      this._generatedReport?.state === "generated"
    );
  }

  displayPublishButton() {
    return (
      !this.isAuthorized(["clinicalStaff", "physician"]) &&
      !this.eSignEnabled &&
      (this._generatedReport?.state === "generated" || this._generatedReport?.state === "submitted")
    );
  }

  displayRejectButton() {
    if (this.isAuthorized(["physician"])) {
      return this.eSignEnabled && this._generatedReport?.state === "submitted";
    }
    return this._generatedReport?.state === "generated" || this._generatedReport?.state === "submitted";
  }

  displayUnrejectButton() {
    if (this.isAuthorized(["physician"])) {
      return this.eSignEnabled && this._generatedReport?.state === "rejectedByPhysician";
    }
    return (
      this._generatedReport?.state === "rejectedByTech" ||
      this._generatedReport?.state === "rejectedByPhysician"
    );
  }

  displayESignForm() {
    return (
      this.isAuthorized(["physician"]) &&
      this.eSignEnabled &&
      this._generatedReport?.state === "submitted" &&
      !this.controlBox.signing
    );
  }

  displayRecordingDurationInput() {
    return this.reportType === "Summary" && (this.displaySubmitButton() || this.displayPublishButton());
  }

  async clickSubmitButton() {
    this.controlBox.submitting = true;

    try {
      if (this.eSignEnabled) {
        this._study.assignedUsers = await this._StudyAssignmentService.getAssignedUsers(
          this._generatedReport.studyId
        );

        if (this._study.assignedUsers.length === 0) {
          await this._$mdDialog.show({
            controller: "AssignStudyController",
            controllerAs: "assignStudy",
            template: assignStudyPug(),
            locals: {study: this._study, mode: "reports"},
          });
        }
      }

      const propertiesToUpdate = {
        state: "submitted",
        technicianSignedBy: this._Authentication.getFullName(),
        technicianSignedAt: new Date().getTime(),
      };
      if (this.meetsMdnCriteria !== this._generatedReport.meetsMdnCriteria) {
        propertiesToUpdate.meetsMdnCriteria = this.meetsMdnCriteria;
      }

      await this._updateReport(this.reportId, propertiesToUpdate);

      // Only upload the recorded duration for Summary items if it's different than the original value
      const updateRecordedDuration =
        this.reportType === "Summary" && this._study?.recordedDuration !== this.recordingDuration;

      const studyRequests = [];
      if (updateRecordedDuration) {
        studyRequests.push(
          this._StudyService.updateStudy(this._generatedReport.studyId, {
            recordedDuration: Number(this.recordingDuration) * 24,
          })
        );
      }
      if (this.markAsFinalized) {
        studyRequests.push(this._StudyService.finalizeStudy(this._generatedReport.studyId));
      }

      await Promise.all(studyRequests);

      this.displayedTechnicianName = propertiesToUpdate.technicianSignedBy;
      this.displayedTechnicianSignature = `Electronically signed by ${propertiesToUpdate.technicianSignedBy}`;
      this.displayedTechnicianTimestamp = formatDateAndTime({
        datetime: propertiesToUpdate.technicianSignedAt,
        zone: this._study.timeZone,
      });

      this._showSuccessPopup("Submitted");
    } catch (err) {
      this._showErrorPopup(
        "Failed to Submit Report",
        err.message || err.data?.detail?.message || err,
        "Please try again or contact support if this issue persists."
      );
    }

    this.controlBox.submitting = false;
    this._$scope.$apply();
  }

  async clickRejectButton() {
    let rejectionConfirmed = false;
    let reasonForRejection;

    try {
      const patient = this._generatedReport.patientName || this._generatedReport.tzSerial;
      const {studyId, reportNumber} = this._generatedReport;
      const htmlContent =
        `<p class="warningMessage">` +
        `  <i class="material-icons dialogInfoIcon"> info </i> Are you sure you want to Reject this ${this.reportType} report?` +
        `</p>` +
        `<table class="marginAuto">` +
        `  <tr><td class="rightAligned rightPadding15"><b>Patient:</b></td><td>${patient}</td></tr>` +
        `  <tr><td class="rightAligned rightPadding15"><b>Study ID:</b></td><td>${studyId}</td></tr>` +
        `  <tr><td class="rightAligned rightPadding15"><b>Report Number:</b></td><td>${reportNumber}</td></tr>` +
        `</table>`;
      reasonForRejection = await this._showConfirmationPopup(htmlContent, "Confirm Report Rejection");
      rejectionConfirmed = true;
    } catch (error) {
      // Do Nothing
    }
    if (rejectionConfirmed) {
      const propertiesToUpdate = {
        state: this.isAuthorized(["clinicalStaff", "physician"]) ? "rejectedByPhysician" : "rejectedByTech",
        reasonForRejection,
      };
      await this._rejectOrUnrejectReport("Reject", propertiesToUpdate);
    }
  }

  async clickConfirmRejectionButton() {
    const propertiesToUpdate = {
      state: "rejectedByTech",
    };
    await this._rejectOrUnrejectReport("Reject", propertiesToUpdate);
  }

  clickUnrejectButton() {
    let propertiesToUpdate;

    // If physician or clinicalStaff, move report back to submitted and keep the tech signature
    if (this.eSignEnabled && this.isAuthorized(["clinicalStaff", "physician"])) {
      propertiesToUpdate = {state: "submitted"};
    }
    // If clinicalStaff without e-signing, move report back to published
    else if (!this.eSignEnabled && this.isAuthorized(["clinicalStaff"])) {
      propertiesToUpdate = {state: "published"};
    }
    // Otherwise, move report back to generated and remove the tech signature (since it will need to be resubmitted)
    else {
      propertiesToUpdate = {state: "generated", technicianSignedBy: null, technicianSignedAt: null};

      this.displayedTechnicianName = null;
      this.displayedTechnicianSignature = null;
      this.displayedTechnicianTimestamp = null;
    }

    return this._rejectOrUnrejectReport("Unreject", propertiesToUpdate);
  }

  async clickSignButton() {
    this.controlBox.signing = true;
    this.isLoading = true;

    // Update the page to show loading circle (requires delay)
    await sleep(10);
    angular.element(() => {
      this._$scope.$apply();
    });

    try {
      const unixTimestamp = new Date().getTime();
      this.displayedPhysicianTimestamp = formatDateAndTime({
        datetime: unixTimestamp,
        zone: this._study.timeZone,
      });
      const body = {
        physicianComment: this._generatedReport.physicianComment || "",
        signedAt: unixTimestamp,
      };

      this.displayedPhysicianSignature = `Electronically signed by ${this._Authentication.getFullName()}`;

      const pdfBuffer = await this._convertReportToPdf("Signing");

      this.loadingMessage = "Uploading signed report";
      const postResponse = await this._GeneratedReportService.signReportPdf(this.reportId, pdfBuffer, body);
      this._generatedReport.publishedUrl = postResponse.publishedUrl;
      this._generatedReport.state = postResponse.state;

      this._showSuccessPopup("Signed");
    } catch (err) {
      this.displayedPhysicianSignature = null;
      this.displayedPhysicianTimestamp = null;
      if (err && err !== "Aborted") {
        const data = err.response?.data;
        let message;
        if (data?.httpStatus === 401) {
          message = [data.title, "Please try again and enter your user credentials correctly."];
        } else if (data?.detail?.name.includes("SignReportError")) {
          message = ["Report has already been signed, please refresh the page."];
        } else {
          message = [err.message || err, "Please try again or contact support if this issue persists."];
        }

        this._showErrorPopup("Failed to Sign Report", ...message);
      }
    }

    this.isLoading = false;
    this.loadingMessage = "";
    this.controlBox.signing = false;
    this._$scope.$apply();
  }

  async clickEditReportButton() {
    let err;
    const reportTitle = `Report ${this._generatedReport.studyId}-${this._generatedReport.reportNumber}`;

    try {
      this.controlBox.editing = true;

      // Obtain a lock on the item
      const selectedItem = await this._InboxItemService.selectItem(this._originalItem, false);

      // Destroy the populated sheets to improve performance
      this.sheets = {};

      this.controlBox.editing = false;
      this._$scope.$apply();

      // Open the Edit Report dialog
      err = await this._$mdDialog
        .show({
          controller: "EditReportController",
          controllerAs: "editReport",
          template: editReportPug(),
          locals: {
            item: this._originalItem,
            selectedItem,
            generatedReport: this._generatedReport,
          },
        })
        .catch((e) => e);

      // If the Edit Report dialog was cancelled, deselect the item and continue
      if (err && err === "Cancelled edits") {
        // Clearing the error here will prompt the application to reload the report
        err = undefined;

        await this._$mdDialog.hide();
        await this._InboxItemService.deselectItem();
      } else if (err) {
        throw err;
      }
    } catch (error) {
      if (error?.message) {
        this._showErrorPopup(
          `Failed to Edit ${reportTitle}`,
          error.message,
          "Please try again or contact support if this issue persists."
        );
      }
      this.controlBox.editing = false;
      this._$scope.$apply();
      return;
    }

    // If edits to the report were successful, reload the report
    try {
      if (!err) {
        this.reportId = null;
        this.reportType = null;
        this.reportConfig = angular.copy(this.DEFAULT_REPORT_CONFIG);
        await this._init();
      }
    } catch (error) {
      this._showErrorPopup(
        `Failed to reload ${reportTitle}`,
        error.message,
        "Please refresh the page or contact support if this issue persists."
      );
    }
  }

  disableEditButton() {
    return this.controlBox.isLoading() || this._originalItem?.locked;
  }

  async clickPublishButton() {
    this.controlBox.publishing = true;
    this.isLoading = true;

    // Update the page to show loading circle (requires delay)
    await sleep(10);
    angular.element(() => {
      this._$scope.$apply();
    });

    try {
      this.displayedTechnicianName = this._Authentication.getFullName();

      const pdfBuffer = await this._convertReportToPdf("Publishing");

      this.loadingMessage = "Uploading published report";
      this._$scope.$apply();

      const postResponse = await this._GeneratedReportService.publishReportPdf(this.reportId, pdfBuffer);
      this._generatedReport.publishedUrl = postResponse.publishedUrl;
      this._generatedReport.state = postResponse.state;

      const propertiesToUpdate = {
        state: "published",
        technicianSignedBy: this.displayedTechnicianName,
        technicianSignedAt: null,
      };
      if (this.meetsMdnCriteria !== this._generatedReport.meetsMdnCriteria) {
        propertiesToUpdate.meetsMdnCriteria = this.meetsMdnCriteria;
      }

      // Update the report's MDN criteria, report state, and attach technician information
      await this._updateReport(this.reportId, propertiesToUpdate);

      // Only upload the recorded duration for Summary items if it's different than the original value
      const updateRecordedDuration =
        this.reportType === "Summary" && this._study?.recordedDuration !== this.recordingDuration;

      const studyRequests = [];
      if (updateRecordedDuration) {
        studyRequests.push(
          this._StudyService.updateStudy(this._generatedReport.studyId, {
            recordedDuration: Number(this.recordingDuration) * 24,
          })
        );
      }
      if (this.markAsFinalized) {
        studyRequests.push(this._StudyService.finalizeStudy(this._generatedReport.studyId));
      }

      await Promise.all(studyRequests);

      this._showSuccessPopup("Published");
    } catch (err) {
      this._generatedReport.publishedUrl = null;
      this._generatedReport.state = "generated";
      this._showErrorPopup(
        "Failed to Publish Report",
        err.message || err,
        "Please try again or contact support if this issue persists."
      );
    }
    this.isLoading = false;
    this.loadingMessage = "";
    this.controlBox.publishing = false;
    this._$scope.$apply();
  }

  clickBackButton() {
    this._$state.go("reports");
  }

  async _updateReport(reportId, propertiesToUpdate) {
    const patchResponse = await this._GeneratedReportService.updateReport(reportId, propertiesToUpdate);
    this._generatedReport.state = patchResponse.state;

    return patchResponse;
  }

  get _studyTypeMap() {
    return {
      mct: "Mobile Cardiac Telemetry",
      mctWithFullDisclosure: "Mobile Cardiac Telemetry",
      cardiacRehab: "Cardiac Rehab",
      wirelessHolter: "Wireless Holter",
      wirelessExtendedHolter: "Wireless Extended Holter",
      cem: "Cardiac Event Monitoring",
    };
  }

  get generatedReport() {
    return this._generatedReport;
  }

  _getUserFriendlyReportType(type) {
    const reportTypesMap = {
      "single-episode": "Single Episode",
      "daily-trend": "Daily Trend",
      summary: "Summary",
      uploaded: "Uploaded",
    };
    return reportTypesMap[type] || "Unknown Report Type";
  }

  /**
   * Takes local, beta semver, or semver versions and compares them
   *
   * Local: contains dirty version, e.g. 0.8.0.4-345-g28627726c
   * Beta semver: pre-1.0.0 version, e.g. 0.7.9.0 or 0.4.3
   *
   * Between versions in different categories, beta semver < semver < local
   *
   * Usage Example (returns true if _versionCreated is equal to or newer than 0.8.1.0)
   * this._versionCompare(this._versionCreated, "v0.8.1.0") >= 0
   *
   * @param {String} v1
   * @example 0.8.0.4-345-g28627726c
   * @example 0.7.9.0
   * @example 1.0.0
   * @param {String} v2
   * @returns {Number} 1 if v1 > v2, -1 if v1 < v2, 0 if v1 = v2
   */
  _versionCompare(v1, v2) {
    const [v1Parsed, v2Parsed] = [v1, v2].map(this._parseVersion);
    let comparison;

    // If one is local, the local version is considered greater
    if (v1Parsed.local || v2Parsed.local) {
      comparison = v1Parsed.local ? 1 : -1;
    }

    // If both are beta versions, compare using semver
    else if (v1Parsed.semver && v2Parsed.semver && v1Parsed.beta && v2Parsed.beta) {
      comparison = semver.compare(v1Parsed.semver, v2Parsed.semver);
    }

    // If only one is beta, the beta version is considered lesser
    else if (v1Parsed.semver && v2Parsed.semver && (v1Parsed.beta || v2Parsed.beta)) {
      comparison = v1Parsed.beta ? -1 : 1;
    }

    // If both are normal versions, compare using semver
    else if (v1Parsed.semver && v2Parsed.semver) {
      comparison = semver.compare(v1Parsed.semver, v2Parsed.semver);
    }

    return comparison;
  }

  /**
   * Generates a version object with metadata about the version
   *
   * value: initial version string
   * semver: parsed semver version or null
   * local: if the version is local
   * beta: pre-1.0.0 version, e.g. 0.7.9.0 or 0.4.3
   *
   * @param {String} version
   * @returns {Object} metadata object with value, semver, local, and beta
   */
  _parseVersion(version) {
    // First assume that version is non-semver
    const parsedVersion = {
      value: version,
      semver: null,
      beta: true,
      local: false,
    };

    // Remove the leading "v"
    if (version?.startsWith("v")) {
      version = version.slice(1);
    }

    // Determine if this is a local version and remove the dirty version, e.g. 0.8.0.4-345-g28627726c
    if (version?.includes("-")) {
      parsedVersion.local = true;
      version = version.slice(0, version.indexOf("-"));
    }

    // Try to parse semver from beta version, e.g. 0.7.9.0 or 0.4.3
    if (version?.startsWith("0.") && (parsedVersion.semver = semver.coerce(version.slice(2)))) {
      parsedVersion.semver = parsedVersion.semver.version;
      parsedVersion.beta = true;
    }
    // Else try to parse valid semver, e.g. 1.2.1
    else if ((parsedVersion.semver = semver.parse(version))) {
      parsedVersion.semver = parsedVersion.semver.version;
      parsedVersion.beta = false;
    }

    return parsedVersion;
  }

  /*
   * ComponentId's are specified in the report config object. Since the report config can't know
   * the values of the components, we get those values here.
   * Some componentId's need a value passed in, like stripNumber
   *
   * PUG example usage:
   * {{ reportController.componentMap[componentId].value }}
   * {{ reportController.componentMap.stripNumber(1) }}
   */
  get componentMap() {
    return {
      title: `${this.reportType} Report`,
      page: (sheetNumber) => `Page ${sheetNumber} of ${Object.keys(this.sheets).length}`,
      reportCreatedAt: () => {
        const action = this._generatedReport.lastModifiedAt ? "Updated" : "Created";
        const datetime = this._generatedReport.lastModifiedAt || this._generatedReport.timestamp;

        return `${action}: ${formatDateAndTime({datetime, zone: this._study.timeZone})} with ${
          this._versionCreated
        }`;
      },
      versionRendered: () => `Rendered in: ${this._backendConfig.softwareVersion}`,
      reportNumber: () => `Report Number: ${this._generatedReport.reportNumber}`,
      patient: {name: "Patient", value: this._getPatientName()},
      studyDeviceSerial: {name: "Device", value: this._generatedReport.tzSerial},
      studyId: {name: "Study ID", value: this._study.id.toString()},
      studyStartDate: {
        name: "Start Date",
        value: this._study.validStudyStartDate
          ? formatDateAndTime({datetime: this._study.studyStartDate, zone: this._study.timeZone})
          : "Unknown Timestamp",
      },
      studyEndDate: {
        name: "End Date",
        value: this._study.validStudyStartDate
          ? formatDateAndTime({datetime: this._study.studyEndDate, zone: this._study.timeZone})
          : "Unknown Timestamp",
      },
      studyDuration: {
        name: "Study Duration",
        value: this._getDisplayedStudyDuration(),
      },
      eventTimestamp: {
        name: "Event Timestamp",
        value: this._originalItem?.validTimestamp
          ? formatDateAndTime({datetime: this._originalItem.timestamp, zone: this._study.timeZone})
          : "Unknown Timestamp",
      },
      trendStartDate: {
        name: "Trend Start Date",
        value: this._originalItem?.validTimestamp
          ? formatDateAndTime({
              datetime: DateTime.fromISO(this._originalItem.timestamp).minus({hours: 24}),
              zone: this._study.timeZone,
            })
          : "Unknown Timestamp",
      },
      trendEndDate: {
        name: "Trend End Date",
        value: this._originalItem?.validTimestamp
          ? formatDateAndTime({datetime: this._originalItem.timestamp, zone: this._study.timeZone})
          : "Unknown Timestamp",
      },
      studyType: {
        name: "Type",
        value: this._studyTypeMap[this._generatedReport.studyType || this._study.studyType],
      },
      studyIndication: {name: "Indication", value: this._study.studyIndication},
      facilityName: {name: "Facility", value: this._generatedReport.facilityName},
      patientName: {name: "Name", value: this._getPatientName()},
      patientId: {
        name: "Patient ID",
        value: this._generatedReport.patientId,
      },
      patientLanguage: {
        name: "Preferred Language",
        value: this._generatedReport.patientLanguage,
      },
      patientDob: {
        name: "DOB",
        value: this._generatedReport.patientDob,
      },
      patientGender: {
        name: "Gender",
        value: this._generatedReport.patientGender,
      },
      patientHeight: {
        name: "Height",
        value: this._generatedReport.patientHeight,
      },
      patientWeight: {
        name: "Weight",
        value: this._generatedReport.patientWeight,
      },
      patientPhone: {
        name: "Phone",
        value: this._generatedReport.patientPhoneNumber,
      },
      patientAddress: {
        name: "Address",
        value: this._generatedReport.patientAddress,
      },
      physicianName: {
        name: "Name",
        value: this._generatedReport.physicianName,
      },
      physicianPhone: {
        name: "Phone",
        value: this._generatedReport.physicianPhoneNumber,
      },
      physicianFacility: {
        name: "Facility",
        value: this._generatedReport.physicianFacility,
      },
      physicianEmail: {
        name: "Email",
        value: this._generatedReport.physicianEmail,
      },
      physicianAddress: {
        name: "Address",
        value: this._generatedReport.physicianAddress,
      },
      stripOrder: (stripIndex) => stripIndex + 1,
      stripPageNumber: (stripIndex) => this.strips[stripIndex].pageNumber,
      stripTimestamp: (stripIndex) => {
        return this.strips[stripIndex].validStartTime
          ? formatDateAndTime({
              datetime: this.strips[stripIndex].startTime,
              zone: this._study.timeZone,
              seconds: true,
            })
          : "Unknown Timestamp";
      },
      stripUserClassification: (stripIndex) => this.strips[stripIndex].userClassification,
      stripComments: (stripIndex) => this.strips[stripIndex].comment,
      meanHR: (stripIndex) => this.strips[stripIndex].meanHR || "-",
      showMinChip: (stripIndex) => this.strips[stripIndex].showMinChip,
      showMaxChip: (stripIndex) => this.strips[stripIndex].showMaxChip,
      stripMeasurementsTable: (stripIndex) => this._getMeasurementsTable(this.strips[stripIndex]),
      triggerType: (stripIndex) => this._EventService.getTriggerType(this.strips[stripIndex].event),
      triggerDescription: (stripIndex) =>
        this._EventService.getTriggerDescriptions(this.strips[stripIndex].event).join("\n"),
      eventClassification: (stripIndex) => this._getEventClassificationForStrip(this.strips[stripIndex]),
      beatMarkerCounts: (stripIndex) => {
        const beatMarkers = this.beatMarkers.find((e) => e.eventId === this.strips[stripIndex].eventId) || [];
        return this._Markers.getBeatMarkerCountLabels(beatMarkers).join("; ");
      },
      physicianComment: this._generatedReport.physicianComment,
      physicianSignatureNameLabel: {value: "Reading Physician Name:"},
      physicianSignatureName: {value: this._Authentication.getFullName()},
      physicianSignatureLabel: {value: "Reading Physician Signature:"},
      physicianSignature: {value: this.displayedPhysicianSignature || ""},
      signatureDateLabel: {value: "Date:"},
      physicianSignatureDate: {value: this.displayedPhysicianTimestamp || ""},
      signatureLine: {value: ""},
      technicianFindings: this._generatedReport.comment,
      technicianSignatureNameLabel: {value: "Technician Name:"},
      technicianSignatureName: {value: this.displayedTechnicianName || ""},
      technicianSignatureLabel: {value: "Technician Signature:"},
      technicianSignature: {value: this.displayedTechnicianSignature || ""},
      technicianSignatureDate: {value: this.displayedTechnicianTimestamp || ""},
    };
  }

  async _getData() {
    const allData = {};
    allData.generatedReport = await this._GeneratedReportService.getGeneratedReport(this.reportId);
    if (allData.generatedReport.reportType !== this.reportType) {
      throw new Error("404 Not Found: Report Type Mismatch");
    }

    const eventPromises = [];

    // Artifact Regions
    if (Array.isArray(allData.generatedReport.qrsExclusionRegions)) {
      allData.generatedReport.artifactRegions = allData.generatedReport.qrsExclusionRegions.map((region) => {
        return {
          startTime: new Date(region.startTime).getTime(),
          endTime: new Date(region.endTime).getTime(),
        };
      });
    } else {
      allData.generatedReport.artifactRegions = [];
    }
    delete allData.generatedReport.qrsExclusionRegions;

    let completedRequestCount = 0;
    allData.generatedReport.strips.forEach((strip) => {
      // if the strip has no measurements, add defaults
      if (!strip.measurements) {
        strip.measurements = this._Markers.DEFAULT_MEASUREMENTS;
      }

      eventPromises.push(
        this._StripService.getStripEvent(strip).then((result) => {
          this.loadingMessage = `Loading report: Strip ${++completedRequestCount}/${
            allData.generatedReport.strips.length
          }`;

          return result;
        })
      );
    });

    const stripEvents = await Promise.all(eventPromises);
    stripEvents.forEach((stripEvent, index) => {
      const strip = allData.generatedReport.strips[index];
      strip.event = stripEvent;

      // Strip midpoint is already calculated as part of fetching the strip event
      strip.middleSample = stripEvent.ecg.centeredSample;

      strip.ecgStripViewerConfig = angular.copy(this._Config.ecgStripViewerConfig);
      strip.sequenceViewerConfig = angular.copy(this._Config.sequenceViewerConfig);
      strip.ecgStripViewerConfig.stripLength = strip.ecgStripViewerConfig.getStripLengthAt25mm(strip);
      strip.sequenceViewerConfig.stripLength = strip.sequenceViewerConfig.getStripLengthAt25mm(strip);

      const leadsToDisplay = allData.generatedReport.strips[index].displayedLeads;
      if (leadsToDisplay) {
        Object.keys(leadsToDisplay).forEach((channel) => {
          if (
            Object.prototype.hasOwnProperty.call(
              allData.generatedReport.strips[index].ecgStripViewerConfig.displayElements.leads,
              channel
            )
          ) {
            allData.generatedReport.strips[index].ecgStripViewerConfig.displayElements.leads[channel] =
              leadsToDisplay[channel];
          }
        });
      }
      const leadInversionStatuses = allData.generatedReport.strips[index].invertedChannels;
      if (leadInversionStatuses) {
        Object.keys(leadInversionStatuses).forEach((channel) => {
          if (
            Object.prototype.hasOwnProperty.call(
              allData.generatedReport.strips[index].ecgStripViewerConfig.inversionStatus.leads,
              channel
            )
          ) {
            allData.generatedReport.strips[index].ecgStripViewerConfig.inversionStatus.leads[channel] =
              leadInversionStatuses[channel];
            allData.generatedReport.strips[index].sequenceViewerConfig.inversionStatus.leads[channel] =
              leadInversionStatuses[channel];
          }
        });
      }
    });
    const studyPromise = this._StudyService.getStudyAndAttachEnrollments(allData.generatedReport.studyId);
    const logoPromise = this._getGeneratedReportLogo(allData.generatedReport);
    let itemPromise = Promise.resolve({});
    if (allData.generatedReport.itemId !== null) {
      itemPromise = this._InboxItemService.getItemById(allData.generatedReport.itemId);
    }
    [allData.study, allData.originalItem, allData.logoInfo] = await Promise.all([
      studyPromise,
      itemPromise,
      logoPromise,
    ]);
    return allData;
  }

  async _getGeneratedReportLogo(generatedReport) {
    let logoFilename;
    let logo;
    try {
      logoFilename = generatedReport.logoFilename;
      logo = await this._Facility.getLogoFile(logoFilename);

      return {logo, logoFilename};
    } catch (err) {
      const popupTitle = "Logo Not Found";
      setTimeout(this._fixMultiPopupBackDrop, 20, popupTitle);

      const errorMessage =
        `This report was generated with a facility logo that could not be found. Contact your administrator to add a valid logo for ${generatedReport.facilityName} before generating any new reports.</br></br>` +
        "Would you like to continue viewing the report using the BitRhythm default logo?";

      this._$mdDialog.show({
        scope: this._$scope,
        preserveScope: true,
        escapeToClose: false,
        multiple: true, // This must be included on the child mdDialog when there are nested mdDialogs
        template:
          "<md-dialog id='generatedReportLogoErrorDialog'>" +
          ` <h2 class="md-title popupTitle">${popupTitle}</h2>` +
          ' <md-dialog-content md-theme="default" layout-margin>' +
          '   <div layout="row">' +
          '     <p class="warningMessage">' +
          `       <i class="material-icons dialogErrorIcon"> error </i> ${errorMessage}` +
          "     </p>" +
          "   </div>" +
          '   <div layout="row">' +
          "     <span flex></span>" +
          '     <md-button class="md-accent" id="returnToReportsPage" ng-click="$ctrl.closeDialogAndReturnToReports()">Back</md-button>' +
          '     <md-button class="md-accent md-raised" id="proceedToReportButton" ng-click="$ctrl.closeDialog()">Proceed</md-button>' +
          "   </div>" +
          " </md-dialog-content>" +
          "</md-dialog>",
      });

      logoFilename = "bitrhythmLogo.svg";
      logo = await this._Facility.getLogoFile(logoFilename);

      return {logo, logoFilename};
    }
  }

  closeDialogAndReturnToReports() {
    this.closeDialog();
    this.clickBackButton();
  }

  _getPatientName() {
    // If the patient name and tzSerial are the same, don't display the patient name
    return this._generatedReport.patientName === this._generatedReport.tzSerial
      ? ""
      : this._generatedReport.patientName;
  }

  _getDisplayedStudyDuration() {
    if (!this.recordingDuration) {
      return "";
    }

    return `${this.recordingDuration} day${this.recordingDuration === 1 ? "" : "s"}`;
  }

  _getEventClassificationForStrip(strip) {
    if (strip.eventClassification) {
      return strip.eventClassification;
    }
    return this._EventService.getEventClassification(strip.event);
  }

  /*
   * Example:
   * {
   *    columns: [
   *      {name: "HR", label: "HR bpm", header:"HR bpm - (2)", value: "441 - 612 - 1000"},
   *      {name: "RR", label: "R-R ms", header:"R-R ms - (2)", value: "60 - 98 - 136"},
   *      {name: "PR", label: "PR ms", header:"PR ms - (0)", value: "--"},
   *      {name: "QRS", label: "QRS ms", header:"QRS ms - (0)", value: "--"},
   *      {name: "QT", label: "QT ms", header:"QT ms - (0)", value: "--"}
   *    ],
   *    totalCount: 2
   * }
   */
  _getMeasurementsTable(strip) {
    if (!strip.formattedTable) {
      strip.formattedTable = {
        columns: [
          {name: "HR", label: "HR bpm"},
          {name: "RR", label: "R-R ms"},
          {name: "PR", label: "PR ms"},
          {name: "QRS", label: "QRS ms"},
          {name: "QT", label: "QT ms"},
        ],
        totalCount: 0,
      };
    }

    if (!strip.hasLoadedMinMeanMax) {
      strip.formattedTable.totalCount = 0;
      strip.hasLoadedMinMeanMax = true;
      strip.formattedTable.columns.forEach((column) => {
        if (column.name !== "HR") {
          this._Markers.updateMinMeanMaxForStrip(column.name, strip);
        }
        const count = this._Markers.getMeasurementCount(column.name, strip);
        strip.formattedTable.totalCount += count;
        column.header = `${column.label} - (${count})`;
        column.value = this._Markers.getMeasurementMinMeanMax(column.name, strip);
        if (column.value.includes("NaN") || column.value.includes("undefined")) {
          strip.hasLoadedMinMeanMax = false;
        }
      });
    }

    return strip.formattedTable;
  }

  /*
   * Takes a report config object, which specifies the elements to be displayed on
   * the report--and their heights--and generates sheets based on individual element
   * heights compared to max sheet height. Generates a new page if an element would go beyond
   * the max height of a page.
   */
  _createSheets() {
    const elements = angular.copy(this.reportConfig[this.reportType].elements);

    const configuredElements = this._configureElements(elements);

    return this._addElementsToSheets(configuredElements);
  }

  async _addElementsToSheets(elements) {
    this.isRendering = true;
    let currentSheet = 1;
    this.sheets[currentSheet] = new Sheet(this.reportConfig, currentSheet);

    // Counters for Mapped Objects
    let indices = this.resetIndices();

    const addElement = (elementName, elementHeight) => {
      const element = {type: elementName};
      switch (elementName) {
        case "eventIndexHeader":
          element.headerIndex = indices.headerIndex;
          indices.headerIndex++;
          break;
        case "eventIndexItem":
          element.stripIndex = indices.eventIndex;
          indices.eventIndex++;
          break;
        case "stripHeader":
          element.stripIndex = indices.stripIndex;
          this.strips[indices.stripIndex].pageNumber = currentSheet;
          break;
        case "stripSegment":
          element.stripIndex = indices.stripIndex;
          element.segmentCount = indices.segmentCount;
          break;
        case "stripFooter":
          element.stripIndex = indices.stripIndex;
          indices.stripIndex++;
          indices.segmentCount = 0;
          break;
        case "hrTrendSection":
        case "pvcBurdenSection":
        case "arrhythmiaTimelineSection":
          element[elementName] = indices[elementName];
          indices[elementName]++;
          break;
        default:
        // do nothing
      }
      return this.sheets[currentSheet].addElement(element, elementHeight);
    };

    // Perform an initial pass, placing elements on the DOM to determine their height
    elements.forEach((e) => addElement(e));
    this.loadingMessage = "Rendering report: Step 1/2"; // Adding Elements to the Page
    await updateDom(this._$scope);

    // calculatedHeights will have equal length to elements, since the temporary page did not contain a header
    const tempSheet = this.sheets[currentSheet];
    const calculatedHeights = Array.from(tempSheet.element.children).map(({clientHeight}) => clientHeight);

    // Record eventIndexHeader height because it will appear again on overflow pages
    const eventIndexHeaderIndex = elements.indexOf("eventIndexHeader");
    const eventIndexHeaderHeight = calculatedHeights[eventIndexHeaderIndex] || 54;

    // Remove all of the elements from the fake sheet
    this.sheets[currentSheet].reset();
    indices = this.resetIndices();
    this.sheets[currentSheet].addHeader();
    await updateDom(this._$scope);

    while (indices.i < elements.length) {
      const groupCount = this._getSiblingCount(elements, indices.i) + 1;
      const groupHeight = calculatedHeights
        .slice(indices.i, indices.i + groupCount)
        .reduce((acc, val) => acc + val, 0);
      if (groupHeight > this.sheets[currentSheet].remainingHeight) {
        // Add a new Sheet
        currentSheet++;
        this.sheets[currentSheet] = new Sheet(this.reportConfig, currentSheet);
        this.sheets[currentSheet].addHeader();

        // This adds a Header for the Event Index if it overflows to the next page
        if (elements[indices.i] === "eventIndexItem") {
          addElement("eventIndexHeader", eventIndexHeaderHeight);
        }
      }

      // Add all elements of the group
      for (let j = 0; j < groupCount; j++) {
        addElement(elements[indices.i], calculatedHeights[indices.i]);
        indices.i++;
      }
    }

    this.loadingMessage = "Rendering report: Step 2/2"; // Finalizing Sheets
    await updateDom(this._$scope);
    for (let i = 1; i <= currentSheet; i++) {
      // Calculate Physician Comments Max Height if it is on the current sheet
      await this._setMaxPhysicianCommentsHeightIfPresent(this.sheets[i]);
    }

    // Finalize all of the sheets at once
    for (let i = 1; i <= currentSheet; i++) {
      this.sheets[i].finalize();
    }

    this.isRendering = false;
  }

  resetIndices() {
    return {
      i: 0,
      stripIndex: 0,
      eventIndex: 0,
      headerIndex: 0,
      segmentCount: 0,
      hrTrendSection: 0,
      pvcBurdenSection: 0,
      arrhythmiaTimelineSection: 0,
    };
  }

  /**
   * Calculates the number of sibling elements for any given element index
   *
   * When adding elements that should be grouped, just set the siblingCount to 1 for that elementName
   *
   * @param {Array<String>} elements
   * @param {Number} i
   * @returns {Boolean}
   */
  _getSiblingCount(elements, i) {
    let siblingCount = 0;
    switch (elements[i]) {
      case "stripHeader":
      case "pvcBurdenHeader":
      case "eventIndexHeader":
      case "hrTrendHeader":
      case "arrhythmiaTimelineHeader":
        // At least 1 item (or section) should be displayed with its header
        // Recurse so that Header, Item, and Footer are together in cases with 1 item (or section)
        siblingCount = this._getSiblingCount(elements, i + 1) + 1;
        break;
      case "stripSegment":
      case "pvcBurdenSection":
      case "eventIndexItem":
      case "hrTrendSection":
      case "arrhythmiaTimelineSection":
        // If the next element is a footer, it should be displayed with this item (or section)
        siblingCount = Number(elements[i + 1]?.endsWith("Footer"));
        break;
      default:
        siblingCount = 0;
    }
    return siblingCount;
  }

  /**
   * The DOM should be updated before calling this function.
   * @param {Object} sheet
   */
  async _setMaxPhysicianCommentsHeightIfPresent(sheet) {
    const commentsElement = sheet.elements.find(({type, originalType}) => {
      return type === "eSignPhysicianComments" || originalType === "eSignPhysicianComments";
    });

    if (commentsElement) {
      await updateDom(this._$scope);
      const commentsDefaultHeight = this.reportConfig.eSignPhysicianComments.height;
      this.maxPhysicianCommentsHeight = commentsDefaultHeight + sheet.remainingHeight;
    }
  }

  _configureElements(elements) {
    // Elements that have multiple parts to configure (e.g. header, content, & footer)
    const configurableElements = ["strips", "eventIndex"];
    const cemExclusionElements = ["hrTrend", "pvcBurden", "arrhythmiaTimeline"];

    if (this.studyType === "cem") {
      cemExclusionElements.forEach((element) => {
        const elementIndex = elements.indexOf(element);
        const elementExists = elementIndex !== -1;
        if (elementExists) {
          elements.splice(elementIndex, 1);
        }
      });
    } else {
      configurableElements.push(...cemExclusionElements);
    }

    // If there are no strips, Remove the Event Index Table
    if (this.strips.length === 0) {
      const elementIndex = elements.indexOf("eventIndex");
      const elementExists = elementIndex !== -1;
      if (elementExists) {
        elements.splice(elementIndex, 1);
      }
    }

    const replacementItems = configurableElements.map((element) => {
      if (elements.includes(element)) {
        const count = this._elementCount(element);
        const replaceFunction = this._getReplaceFunction(element);
        return this._createReplacementElements(element, count, replaceFunction);
      }
      return "Element not included in this report";
    });

    const configuredElements = elements.flatMap((element) => {
      if (configurableElements.includes(element)) {
        const index = configurableElements.indexOf(element);
        return replacementItems[index];
      }
      return element;
    });

    return configuredElements;
  }

  _createReplacementElements(element, count, replaceFunction) {
    const sectionNameMap = {
      eventIndex: "Item",
      hrTrend: "Section",
      pvcBurden: "Section",
      arrhythmiaTimeline: "Section",
      strip: "Segment",
    };

    if (element === "strips") {
      element = "strip";
    }

    const header = `${element}Header`;
    const footer = `${element}Footer`;
    const sectionName = element + sectionNameMap[element];

    return replaceFunction(sectionName, header, footer, count);
  }

  _elementCount(element) {
    if (element === "eventIndex" || element === "strips") {
      return this.strips.length;
    }
    if (["arrhythmiaTimeline", "pvcBurden", "hrTrend"].includes(element)) {
      const totalDays = moment(this.endTime).diff(moment(this.startTime), "days");
      const numberOfSections = Math.ceil(totalDays / 7);
      return numberOfSections;
    }
    return 0;
  }

  _getReplaceFunction(element) {
    if (element === "strips") {
      return this._wrapEachWithSpacer;
    }
    return this._wrapAll;
  }

  _wrapEachWithSpacer(sectionName, header, footer, count) {
    const result = [];
    for (let i = 0; i < count; i++) {
      result.push(header, sectionName, footer);
      result.push("verticalSpacer");
    }
    return result;
  }

  _wrapAll(sectionName, header, footer, count) {
    const sections = Array.from({length: count}, () => sectionName);
    return [header, ...sections, footer];
  }

  /**
   * Creates an internal link on a PDF
   * Adapted from https://github.com/Hopding/pdf-lib/issues/123
   *
   * Refer to: https://opensource.adobe.com/dc-acrobat-sdk-docs/pdfstandards/PDF32000_2008.pdf
   * See Section 12.5 for specifications on Annotations
   * See Section 12.5.6.5 for specifications on Link Annotations
   * See Section 12.3.2 for specifications on Destinations
   *
   * @param {Object} pdfDoc
   * @param {Number} targetPage
   * @param {Number} targetY
   * @param {Array<Number>} linkPosition [startX, startY, endX, endY]
   * @returns {PDFObject}
   */
  _createLink({pdfDoc, targetPage, targetY, linkPosition}) {
    const pageRef = pdfDoc.getPage(targetPage).ref;

    return pdfDoc.context.register(
      pdfDoc.context.obj({
        Type: "Annot",
        Subtype: "Link",
        // Define a destination in XYZ mode (X-Y-Zoom viewport)
        // Values of null will not affect the viewer when the link is clicked
        Dest: [pageRef, "XYZ", null, targetY, null],
        Rect: linkPosition,
      })
    );
  }

  _determineLinkPosition(sheetElement, linkElement, pageHeight, scaleDPI) {
    const linkSheetBounds = sheetElement.getBoundingClientRect();
    const linkBounds = linkElement.parentElement.getBoundingClientRect();

    // these coordinates are relative to the upper left (typical HTML origin) at 96dpi
    const xOffset = linkBounds.x - linkSheetBounds.x;
    const yOffset = linkBounds.y - linkSheetBounds.y;
    const {width, height} = linkBounds;

    // these coordinates are relative to the lower left (pdf-lib origin) at the PDF's dpi
    const startX = scaleDPI(xOffset);
    const endX = startX + scaleDPI(width);
    const startY = pageHeight - scaleDPI(yOffset + height);
    const endY = startY + scaleDPI(height);

    return [startX, startY, endX, endY];
  }

  _determineTargetY(sheetElement, targetElement, pageHeight, scaleDPI) {
    const targetSheetBounds = sheetElement.getBoundingClientRect();
    const targetBounds = targetElement.getBoundingClientRect();

    // these coordinates are relative to the upper left (typical HTML origin) at 96dpi
    const yOffset = targetBounds.y - targetSheetBounds.y;

    // these coordinates are relative to the lower left (pdf-lib origin) at the PDF's dpi
    return pageHeight - scaleDPI(yOffset);
  }

  /**
   * Finds links within the given sheets and converts them to match pdf-lib format
   * Then adds annotations to the document
   *
   * @param {Object} pdfDoc
   * @param {Array<Element>} sheetArray
   * @param {Number} pageHeight in the PDF's dpi
   * @param {Function} scaleDPI
   */
  _addInternalLinks(pdfDoc, sheetArray, pageHeight, scaleDPI) {
    // Find all of the elements on the page that are linked by other elements on the page
    const links = [];
    Array.from(document.links).forEach((linkElement) => {
      const linkPage = sheetArray.findIndex((e) => e.contains(linkElement));
      if (linkPage === -1) {
        // only use link if it occurs somewhere in the report
        return;
      }

      const regexp = /#(?<targetId>[^/]+)$/;
      const matches = linkElement.href?.match(regexp);
      if (!matches) {
        // only use link if it ends in an element id
        return;
      }

      const {targetId} = matches.groups;
      const targetElement = document.getElementById(targetId);
      if (!targetElement) {
        // only use link if it links to a valid element
        return;
      }

      const targetPage = sheetArray.findIndex((e) => e.contains(targetElement));
      if (targetPage === -1) {
        // only use link if the target occurs somewhere in the report
        return;
      }

      links.push({
        pdfDoc,
        linkPage,
        linkPosition: this._determineLinkPosition(sheetArray[linkPage], linkElement, pageHeight, scaleDPI),
        targetPage,
        targetY: this._determineTargetY(sheetArray[targetPage], targetElement, pageHeight, scaleDPI),
      });
    });

    // Links must be grouped by page because each page in a PDF can only have one "Annots" entry
    const linksGroupedByPage = groupBy(links, "linkPage");
    Object.entries(linksGroupedByPage).forEach(([linkPage, linksForThisPage]) => {
      // Create clickable regions to navigate each link
      const annotations = pdfDoc.context.obj(linksForThisPage.map((link) => this._createLink(link)));

      pdfDoc.getPage(Number(linkPage)).node.set(PDFName.of("Annots"), annotations);
    });
  }

  /**
   * Adds a PDF outline to the document based on the section headers within the given sheets
   * Note: Only supports 1 level of indentation
   *
   * @param {Object} pdfDoc
   * @param {Array<Element>} sheetArray
   * @param {Number} pageHeight in the PDF's dpi
   * @param {Function} scaleDPI
   */
  _addPdfOutline(pdfDoc, sheetArray, pageHeight, scaleDPI) {
    // Find elements with attribute "section-header"
    const headers = Array.from(document.querySelectorAll("[section-header]")).reduce((acc, headerElement) => {
      // only use header if it occurs somewhere in the report
      const targetPage = sheetArray.findIndex((e) => e.contains(headerElement));
      if (targetPage !== -1) {
        // Empty tags are "" while omitted tags are null
        const isParent = acc.length === 0 || headerElement.getAttribute("section-parent") === "";
        const arrayToUse = isParent ? acc : acc[acc.length - 1].headers;

        arrayToUse.push({
          displayedText: headerElement.getAttribute("section-header"),
          targetPageRef: pdfDoc.getPage(targetPage).ref,
          targetY: this._determineTargetY(sheetArray[targetPage], headerElement, pageHeight, scaleDPI),
          itemRef: pdfDoc.context.nextRef(),
          headers: [],
        });
      }
      return acc;
    }, []);

    const outlineRef = pdfDoc.context.nextRef();

    // Register Outline
    pdfDoc.context.assign(
      outlineRef,
      PDFDict.fromMapWithContext(
        pdfDoc.context.obj({
          Type: "Outlines",
          // Create Headers recursively
          ...this._createHeaders(pdfDoc, headers, outlineRef),
        }),
        pdfDoc.context
      )
    );

    // Add Outline to Document
    pdfDoc.catalog.set(PDFName.of("Outlines"), outlineRef);
  }

  _createHeaders(pdfDoc, headers, parentRef) {
    if (headers.length === 0) {
      // Exit and return no metadata
      return {};
    }

    // Transform Header Entries for this hierarchical level
    headers.forEach(({displayedText, targetPageRef, targetY, itemRef, headers: subHeaders}, i) => {
      const isLast = i === headers.length - 1;
      const nextOrPrev = isLast ? "Prev" : "Next";
      const nextOrPrevRef = headers[i + (isLast ? -1 : 1)]?.itemRef;

      // Add Entry to Outline
      pdfDoc.context.assign(
        itemRef,
        PDFDict.fromMapWithContext(
          pdfDoc.context.obj({
            Title: PDFString.of(displayedText),
            Parent: parentRef,
            // Every entry points to the next entry, except for the last one, which points to the previous
            [nextOrPrev]: nextOrPrevRef,
            Dest: [targetPageRef, "XYZ", 0, targetY, 0],
            // Recurse over sub headers
            ...this._createHeaders(pdfDoc, subHeaders, itemRef),
          }),
          pdfDoc.context
        )
      );
    });

    // Return metadata so that its parent can link properly
    return {
      First: headers[0].itemRef,
      Last: headers[headers.length - 1].itemRef,
      Count: headers.length,
    };
  }

  async _convertReportToPdf(progressMessage, scaleDPI = (v) => v * (72 / 96)) {
    const sheetElements = document.getElementById("generatedReport")?.children;
    if (!sheetElements?.length) {
      throw new Error("No sheets found when converting to PDF");
    }

    const pdfDoc = await PDFDocument.create();
    const [PAGE_WIDTH, PAGE_HEIGHT] = PageSizes.Letter;

    for (let i = 0; i < sheetElements.length; i++) {
      if (progressMessage) {
        this.loadingMessage = `${progressMessage} page ${i + 1} of ${sheetElements.length}`;
        this._$scope.$apply();
      }

      // Convert the elements to a dataURL
      const sheetDataUrl = await toJpeg(sheetElements[i], {
        pixelRatio: 300 / 96,
        quality: 0.5,
      });

      // Add a blank page to the PDF
      const newPage = pdfDoc.addPage([PAGE_WIDTH, PAGE_HEIGHT]);

      // Embed the image in the document and position it on the new page (fill page)
      newPage.drawImage(await pdfDoc.embedJpg(sheetDataUrl), {
        x: 0,
        y: 0,
        width: newPage.getWidth(),
        height: newPage.getHeight(),
      });
    }

    this._addInternalLinks(pdfDoc, Array.from(sheetElements), PAGE_HEIGHT, scaleDPI);
    this._addPdfOutline(pdfDoc, Array.from(sheetElements), PAGE_HEIGHT, scaleDPI);

    return (await pdfDoc.save()).buffer;
  }

  async _rejectOrUnrejectReport(userFriendlyState, propertiesToUpdate) {
    try {
      this.controlBox.rejecting = true;
      await this._updateReport(this.reportId, propertiesToUpdate);
      this._showSuccessPopup(`${userFriendlyState}ed`);
    } catch (err) {
      this._showErrorPopup(
        `Failed to ${userFriendlyState} Report`,
        err.message,
        "Please try again or contact support if this issue persists."
      );
    }
    this.controlBox.rejecting = false;
    this._$scope.$apply();
  }

  _setReportConfigValues() {
    this.summaryConfig = this.reportConfig.summary;
    this.eventIndexHeaderConfig = this.reportConfig.eventIndexHeader;
    this.eventIndexItemConfig = this.reportConfig.eventIndexItem;
    this.eventIndexFooterConfig = this.reportConfig.eventIndexFooter;
    this.headerConfig = this.reportConfig.header;
    this.stripHeaderConfig = this.reportConfig.stripHeader;
    this.stripFooterConfig = this.reportConfig.stripFooter;
    this.physicianCommentsConfig = this.reportConfig.physicianComments;
    this.technicianFindingsConfig = this.reportConfig.technicianFindings;
    this.eSignPhysicianCommentsConfig = this.reportConfig.eSignPhysicianComments;
    this.donutChartsConfig = this.reportConfig.donutCharts;
    this.footerConfig = this.reportConfig.footer;
  }

  _formatReportComments() {
    const {elements} = this.reportConfig[this.reportType];

    // Replace physicianComments if eSign workflow is enabled and user is physician
    if (this.eSignEnabled && this.isAuthorized(["physician"])) {
      const index = elements.indexOf("physicianComments");
      elements[index] = "eSignPhysicianComments";
    }
  }

  _formatReportSummary() {
    // The Right column of the summary component (report summary, study summary)
    const rightColumn = this.reportConfig.summary.columns[1];
    if (this._generatedReport.itemId === null) {
      // No Timestamp/Start/End/End Date
      // Delete the Report Summary (It is present by default)
      rightColumn.splice(
        rightColumn.findIndex(({title}) => title === "Report Summary"),
        1
      );
    } else if (this.reportType === "Summary") {
      // Delete the Report Summary (It is present by default)
      rightColumn.splice(
        rightColumn.findIndex(({title}) => title === "Report Summary"),
        1
      );

      // Add the end date row to the study summary after the second element
      const studySummary = rightColumn.find(({title}) => title === "Study Summary");
      const endDateRow = [
        {componentId: "studyEndDate", width: ""},
        {componentId: "studyDuration", width: ""},
      ];
      studySummary.rows.splice(2, 0, endDateRow);
    } else if (this.reportType === "Daily Trend") {
      // Add the Trend Start and End (Overwrites the Event Timestamp)
      const reportSummary = rightColumn.find(({title}) => title === "Report Summary");
      reportSummary.rows[0] = [{componentId: "trendStartDate", width: ""}];
      reportSummary.rows[1] = [{componentId: "trendEndDate", width: ""}];
    }
  }

  _formatAnalysisSummary() {
    // Calculate the min mean, max mean, sum of means, and count
    const {min, minHref, tiedMin, max, maxHref, tiedMax, sum, count} = this.strips.reduce(
      (data, strip, i) => {
        const {mean} = strip.measurements.HR;

        // Only consider strips that have at least one R-R
        if (mean !== undefined) {
          const stripHref = {
            href: `#strip-header-${i}`,
            hrefText: formatDateAndTime({
              datetime: strip.startTime,
              zone: this._study.timeZone,
              seconds: true,
            }),
          };

          data.sum += mean;
          data.count++;

          // Track the min value and the strip first strip that has the min
          if (mean < data.min) {
            data.min = mean;
            data.minHref = stripHref;
            data.tiedMin = 0;
          } else if (mean === data.min) {
            data.tiedMin++;
          }

          // Track the max value and the strip first strip that has the max
          if (mean > data.max) {
            data.max = mean;
            data.maxHref = stripHref;
            data.tiedMax = 0;
          } else if (mean === data.max) {
            data.tiedMax++;
          }
        }
        return data;
      },
      {min: Infinity, max: 0, sum: 0, count: 0}
    );

    if (this.features.heartRateSummary) {
      // Update boolean values on strips for displaying min/max chips
      this.strips.forEach((strip) => {
        const {mean} = strip.measurements.HR;
        strip.showMinChip = this.chartToggles?.heartRateSummary && mean === min;
        strip.showMaxChip = this.chartToggles?.heartRateSummary && mean === max;
      });
    }

    // Calculate the overall mean
    const mean = Math.round(sum / count);

    // Display how many other strips share the same min/max (if any)
    const otherMinStrips = tiedMin && ` and ${tiedMin} other strip${tiedMin === 1 ? "" : "s"}`;
    const otherMaxStrips = tiedMax && ` and ${tiedMax} other strip${tiedMax === 1 ? "" : "s"}`;

    // Attach nested array of values to the controller
    this.heartRateSummary = [
      ["Min", !count ? ["N/A"] : [{strong: `${min}bpm`}, " at ", minHref, otherMinStrips]],
      ["Avg", !count ? ["N/A"] : [{strong: `${mean}bpm`}]],
      ["Max", !count ? ["N/A"] : [{strong: `${max}bpm`}, " at ", maxHref, otherMaxStrips]],
    ];
  }

  _formatStripIndex() {
    if (!this.features.heartRateSummary) {
      // Remove the Heart Rate Column when the feature is off
      const columns = this.reportConfig.eventIndexItem.items;

      const hrIndex = columns.findIndex((c) => c.componentId === "meanHR");
      columns.splice(hrIndex, 1);

      columns.find((c) => c.componentId === "stripComments").width = "40"; // Change the 30 to 40
    } else if (this.chartToggles && !this.chartToggles.heartRateSummary) {
      // If there are no min/max chips (when the heartRateSummary is off), then redistribute the column widths
      const columns = this.reportConfig.eventIndexItem.items;

      columns.find((c) => c.componentId === "meanHR").width = "5"; // Change the 10 to 5
      columns.find((c) => c.componentId === "stripComments").width = "35"; // Change the 30 to 35
    }
  }

  _formatChartsAndGraphs() {
    this.heartRateTrend = this._generatedReport.heartRateTrend;
    this.arrhythmiaData = this._generatedReport.arrhythmiaData || [];
    this.pvcBurden = this._generatedReport.pvcBurden || [];

    this.arrhythmiaTimelineParentId = "arrhythmiaTimelineContainer";
    this.heartRateTrendParentId = "heartRateTrendContainer";

    this.studyStartDate = this._study.studyStartDate;
    this.studyEndDate = this._study.studyEndDate;

    this.artifactRegions = this._ExcludeArtifactService.mergeRegions(this._generatedReport.artifactRegions);

    const veData = this._generatedReport.ventricularEctopy;
    const veTotal = this._veDataService.getPercentOfTotal(veData);
    this.veGroupings = this._veDataService.getGroupings(veData);
    this.veAdditionalData = this._veDataService.formatAdditionalData(veTotal);

    this.arrhythmiaEpisodesData = this._ArrhythmiaDataService.getArrhythmiaEpisodesData(this.arrhythmiaData);
    this.aeAdditionalData = this._ArrhythmiaDataService.formatAdditionalData(this.arrhythmiaData);

    const duration = this._ArrhythmiaDataService.getDuration(
      this.reportType,
      this.studyStartDate,
      this.studyEndDate
    );
    this.arrhythmiaBurdenData = this._ArrhythmiaDataService.getArrhythmiaBurdenData(
      this.arrhythmiaData,
      this.artifactRegions,
      duration
    );

    this.afStats = this._afStatsService.getStats(this.arrhythmiaData, this.artifactRegions);
    this.afAdditionalData = this._afStatsService.formatAdditionalData(this.afStats);

    if (this.chartToggles) {
      const elementsArray = this.reportConfig[this.reportType].elements;
      // Remove arrhythmiaTimeline, hrTrend, or pvcBurden if they are disabled
      if (this.chartToggles.arrhythmiaTimeline === false) {
        elementsArray.splice(elementsArray.indexOf("arrhythmiaTimeline"), 1);
      }
      if (this.chartToggles.heartRateTrend === false) {
        elementsArray.splice(elementsArray.indexOf("hrTrend"), 1);
      }
      if (this.chartToggles.pvcBurden === false) {
        elementsArray.splice(elementsArray.indexOf("pvcBurden"), 1);
      }
      if (!this.features.heartRateSummary || !this.chartToggles.heartRateSummary) {
        // Include all falsy values because older reports without chartToggles.heartRateSummary do not have heartRateSummary
        elementsArray.splice(elementsArray.indexOf("heartRateSummary"), 1);
      }

      // Remove Donut charts if they are all disabled
      if (
        this.chartToggles.arrhythmiaEpisode === false &&
        this.chartToggles.arrhythmiaBurden === false &&
        this.chartToggles.ventricularEctopy === false &&
        this.chartToggles.atrialFibrillationStats === false
      ) {
        elementsArray.splice(elementsArray.indexOf("donutCharts"), 1);
      } else {
        ["arrhythmiaEpisode", "arrhythmiaBurden", "ventricularEctopy", "atrialFibrillationStats"].forEach(
          (donutChartType) => {
            if (this.chartToggles[donutChartType] === false) {
              this.donutChartsConfig[donutChartType].display = false;
            }
          }
        );
      }
    }
  }

  /**
   * Asks the User to confirm the action
   * @param {String} htmlContent
   * @param {String} popupTitle
   * @throws {Error} if the user clicks cancel
   * @returns {Promise}
   */
  _showConfirmationPopup(htmlContent, popupTitle = "Confirm Action") {
    setTimeout(this._fixMultiPopupBackDrop, 20, popupTitle);

    return this._$mdDialog
      .show(
        this._$mdDialog
          .prompt()
          .title(popupTitle)
          .htmlContent(htmlContent)
          .placeholder("Reason *")
          .required(true)
          .initialValue("")
          .ok("Ok")
          .cancel("Cancel")
          .multiple(true)
      )
      .catch(() => {
        throw new Error("Confirmation Cancelled");
      });
  }

  _showSuccessPopup(reportState) {
    let htmlContent = `${this.reportType} Report was successfully ${reportState}.`;

    if (reportState === "Submitted") {
      htmlContent = `${this.reportType} Report was successfully Signed and ${reportState}.`;
    }

    return this._$mdDialog.show(
      this._$mdDialog.alert().title(`Report ${reportState}`).htmlContent(htmlContent).ok("Ok")
    );
  }

  _showErrorPopup(popupTitle, ...lines) {
    const content = lines.join("<br><br>");
    setTimeout(this._fixMultiPopupBackDrop, 20, popupTitle);

    return this._$mdDialog.show(
      this._$mdDialog
        .alert()
        .title(popupTitle)
        .htmlContent(
          `<p class="warningMessage"><i class="material-icons dialogErrorIcon"> error </i>&nbsp;${content}</p>`
        )
        .ok("Ok")
        .multiple(true)
    );
  }

  _fixMultiPopupBackDrop(textToSearchFor) {
    const dialog = Array.from(document.getElementsByClassName("md-dialog-container")).find((e) =>
      e.textContent.toUpperCase().includes(textToSearchFor.toUpperCase())
    );
    let computedStyle = window.getComputedStyle(dialog);
    let currentIndex = Number(computedStyle.getPropertyValue("z-index"));
    dialog.style["z-index"] = `${currentIndex + 2}`;

    const dialogBackdrop = document.getElementsByClassName("md-dialog-backdrop")[0];
    computedStyle = window.getComputedStyle(dialogBackdrop);
    currentIndex = Number(computedStyle.getPropertyValue("z-index"));
    dialogBackdrop.style["z-index"] = `${currentIndex + 2}`;
  }

  get startTime() {
    if (this.reportType === "Summary") {
      return this.summaryStartTime;
    }
    return this.dailyTrendStartTime;
  }

  get endTime() {
    if (this.reportType === "Summary") {
      return this.summaryEndTime;
    }
    return this.dailyTrendEndTime;
  }

  get summaryStartTime() {
    const studyStartTime = moment(this.studyStartDate).valueOf();
    const daysSinceFirstDayOfWeek = moment(studyStartTime).weekday();
    const firstDayOfWeek = moment(studyStartTime).subtract(daysSinceFirstDayOfWeek, "days");
    return moment(firstDayOfWeek).startOf("day");
  }

  get summaryEndTime() {
    const studyEndTime = moment(this.studyEndDate).valueOf();
    const daysTillLastDayOfWeek = 6 - moment(studyEndTime).weekday();
    const lastDayOfWeek = moment(studyEndTime).add(daysTillLastDayOfWeek, "days");
    return moment(lastDayOfWeek).endOf("day");
  }

  get dailyTrendStartTime() {
    return this.heartRateTrend.boxPlots[0].startTime;
  }

  get dailyTrendEndTime() {
    return this.dailyTrendStartTime + 60 * 60 * 24 * 1000;
  }
}

function sleep(ms) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}

async function updateDom($scope) {
  await sleep(1);
  $scope.$apply();
  await sleep(1);
}
