/* eslint-env browser */

/* @ngInject */
export default class ReportsController {
  constructor($window, SearchService) {
    this._$window = $window;
    this._Search = SearchService;

    this._Search.autoFillValues = [
      "study:",
      "study-type:",
      "facility:",
      "number:",
      "type:",
      "findings:",
      "is:mdn",
      "timestamp:",
      "patient:",
      "gender:",
      "physician:",
      "physician-name:",
      "physician-phone:",
      "physician-facility:",
      "physician-email:",
      "physician-address:",
    ];

    const searchFromStorage = this._$window.localStorage.getItem("search");
    if (searchFromStorage) {
      const currentTime = new Date().getTime();
      const search = JSON.parse(searchFromStorage);

      if (currentTime < search.timeExpired) {
        this._Search.searchText = search.text;
      }
      this._$window.localStorage.removeItem("search");
    }
  }
}
